<template>
  <div>
    <v-fab-transition>
      <v-btn
          fixed
          bottom
          right
          fab
          :x-large="!$vuetify.breakpoint.xsOnly"
          :small="$vuetify.breakpoint.xsOnly"
          color="#22234a"
          dark
          @click="eventoAtencionCiudadana()"
      >
        <v-icon 
          :x-large="!$vuetify.breakpoint.xsOnly"
        >mdi-help-circle-outline</v-icon>
      </v-btn>

    </v-fab-transition>
    <v-dialog 
          v-model="showAtencionCiudadana" 
          :max-width="$vuetify.breakpoint.xsOnly ? '265px' : '409px'" 
          hide-overlay
          persistent :content-class="$vuetify.breakpoint.xsOnly ? 'my-custom-xs' : 'my-custom-dialog'">
      <!-- Contenido de tu modal -->
      <v-responsive
        class="overflow-y-hidden fill-height"
        :height="$vuetify.breakpoint.xsOnly ? '550px' : '629px'"
      >
      <!-- <v-responsive
        class="overflow-y-hidden fill-height"
        height="629"
      > -->
        <v-card class="rounded-lg d-flex flex-column fill-height" color="#FFF">
          <v-system-bar
            window
            dark
            :height="$vuetify.breakpoint.xsOnly ? $vuetify.breakpoint.width > 407 ? '70px' : '100px' : '50px'"
            color="#22234a"
            
          >
            <span class="ml-2 white--text font-weight-regular" :class="dinamicClass">Asistente virtual</span>
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              :large="$vuetify.breakpoint.xsOnly ? false : true"
              :small="$vuetify.breakpoint.xsOnly ? true : false"
              :class="$vuetify.breakpoint.xsOnly ? 'mr-2' : ''"
              @click="closeAtencionCiudadana"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-system-bar>
          <v-app-bar
            flat
            prominent
            color="#FFF"
          >
          <v-row>
            <v-col align="center">
              <v-img
                src="@/assets/img/logo-secretaria.png"
                max-width="175px"
                class="mt-6 text-center"
                contain
                ></v-img>
            </v-col>
          </v-row>
          </v-app-bar>
          <v-card-text class="flex-grow-1 overflow-y-auto" ref="contenedor">
            <!-- Contenido del modal -->
            <v-container class="contenedor1">
              <div class="text-center" v-if="loadingRespuesta">
                <v-progress-circular
                  indeterminate
                  color="#22234a"
                ></v-progress-circular>
              </div>
              <v-row justify="space-around" class="d-flex flex-column" v-else>
                <v-card v-for="(message, index) in messages" :key="index" flat color="rgba(0,0,0,0)">              
                  <v-list-item
                      :key="index"
                      v-if="message.from != 'You'"
                      color="rgba(0,0,0,0)"
                    >
                      <template v-if="!$vuetify.breakpoint.xsOnly">
                        <v-list-item-avatar class="align-self-start mr-1" color="#22234a" v-if="message.showAvatar">                     
                          <v-icon dark>
                            mdi-help-circle-outline
                          </v-icon>                      
                        </v-list-item-avatar>
                        <v-list-item-avatar class="align-self-start mr-1" v-else>

                        </v-list-item-avatar>
                      </template>
                      <v-list-item-content class="received-message px-0" :style="message.loading ? 'flex: none !important' : ''">
                        <v-progress-circular
                          v-if="message.loading"
                          indeterminate
                          color="#22234a"
                          class="align-self-start"
                        ></v-progress-circular>                 
                        <v-card v-else elevation="2" :color="message.colorBg" class="flex-none rounded-lg" 
                        @click="message.activable && handleCardClick(message)"
                        :ripple="message.activable" 
                        :class="{ 'no-clickable': !message.activable }">
                          <v-card-text class="pa-2 d-flex flex-column" :class="message.colorText">
                            <div v-if="$vuetify.breakpoint.xsOnly && message.showAvatar" class="align-self-start text-subtitle-1 d-block" style="font-size: large !important; color: #22234a;">Asistente virtuaal</div>
                            <div class="align-self-start text-subtitle-1" v-html="message.message" id="message1" style="margin-bottom: 0; word-break: break-word"></div> 
                          </v-card-text>                  
                        </v-card>
                      </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-else :key="index">
                    <v-list-item-content class="sent-message justify-end px-1">
                      <v-card elevation="2" :color="message.colorBg" class="flex-none rounded-lg" 
                      @click="message.activable && menuPreguntasClick()"
                      :ripple="message.activable" 
                      :class="{ 'no-clickable': !message.activable }">
                        <v-card-text class="pa-2 d-flex flex-column" :class="message.colorText">                                              
                          <div v-html="message.message" id="message1"style="word-break: break-word"></div> 
                        </v-card-text>                      
                      </v-card>
                    </v-list-item-content>
                    <v-list-item-avatar class="align-self-start ml-2" color="#22234a" v-if="message.showAvatar">

                    </v-list-item-avatar>                    
                </v-list-item> 
                </v-card>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-responsive>
    </v-dialog>
  </div>
</template>

<script>
    export default {
      data: () => ({
        showAtencionCiudadana: false,
        totalPregunta: 0,
        loadingRespuesta: true,
        messages: [
          // {
          //   from: 'Bot',
          //   message: '<p>Bienvenido al Portal de Empleo. <br> Soy tu asistente virtual. Estaré guiándolo a la opción que más se adecúe a su tipo de solicitud. Por favor seleccione la pregunta correspondiente a su duda:</p>',
          //   colorBg: '#F2F2F7',
          //   colorText: 'black--text',
          //   showAvatar: true,
          //   activable: false,
          //   loading: false,
          // },
          // {
          //   from: 'Bot',
          //   message: '<p>¿Como crear mi CV?</p>',
          //   respuesta: '<p>Respuesta 1</p>',
          //   colorBg: '#BDC0DF',
          //   colorText: 'black--text',
          //   showAvatar: false,
          //   activable: true,
          // },
          // {
          //   from: 'You',
          //   message: `<p>Sure, I'll see you later.</p>`,
          //   colorBg: '#22234a',
          //   colorText: 'white--text',
          //   showAvatar: false,
          // },
        ],
      }),
      methods: {
        eventoAtencionCiudadana(){
          if(this.showAtencionCiudadana === true){
            this.closeAtencionCiudadana();
          }else{
            this.openAtencionCiudadana();
          }
        },
        async openAtencionCiudadana() {
          // if(this.messages.length === 0){
          //   this.agregarMsgWelcome()
          //   await this.getListPreguntas()
          // }
          this.showAtencionCiudadana = true
          this.$nextTick(() => {
            this.$refs.contenedor.scrollTop = 0;
          });
        },
        closeAtencionCiudadana() {
          this.showAtencionCiudadana = false
          this.messages = this.messages.splice(0, this.totalPregunta)
        },
        async getListPreguntas(){
          try {
            this.loadingRespuesta = true;
            this.agregarMsgWelcome()
            const response = await this.services.inicio.getPreguntas()

            if (response.status == 200) {
              let data = response?.data;
              data = data.map(pregunta => {
                return {
                  from: 'Bot',
                  message: `<p>`+pregunta.pregunta+`</p>`,
                  idPregunta: pregunta.id,
                  colorBg: '#BDC0DF',
                  colorText: 'black--text',
                  showAvatar: false,
                  activable: true,      
                  loading: false
                }
              })
              this.totalPregunta = Number(response?.data.length) + 1
              this.messages.push(...data);
            }
          }catch (e) {

          }finally {
            this.loadingRespuesta = false
          }
        },
        agregarMsgWelcome(){
          let welcome = {
            from: 'Bot',
            message: '<p>Bienvenido al Portal de Empleo. <br> Soy tu asistente virtual. Estaré guiándote a la opción que más se adecúe a tu tipo de solicitud. Por favor selecciona la pregunta correspondiente a tu duda:</p>',
            colorBg: '#F2F2F7',
            colorText: 'black--text',
            showAvatar: true,
            activable: false,
            loading: false,
          }
          this.messages.push(welcome)
        },
        async menuPreguntasClick(){
          this.messages = [];
          await this.getListPreguntas()
        },
        async handleCardClick(item){
          const contenedor = this.$refs.contenedor;
          // console.log(contenedor)
          if (item.activable) {
            // Lógica a ejecutar si la tarjeta es activable y se hace clic en ella
            let miMessage = {
              from: 'You',
              message: item.message,
              colorBg: '#7781E0',
              colorText: 'white--text',
              showAvatar: false,       
              activable: false,   
              loading: false
            }
            this.messages.push(miMessage);

            let respuestaBot = {
              from: 'Bot',
              message: '',
              colorBg: '#F2F2F7',
              colorText: 'black--text',
              showAvatar: true,
              activable: false,
              loading: true        
            }
            this.messages.push(respuestaBot)

            this.$nextTick(() => {
              contenedor.scrollTop = contenedor.scrollHeight;
            });

            // let ultimoValor = this.messages[this.messages.length - 1];
            try {
              const response = await this.services.inicio.getRepuestas(item.idPregunta)

              if (response.status == 200) {
                let data = response?.data;
                setTimeout(() => {
                  // Aquí iría la lógica para manejar la respuesta
                  // Por ejemplo, cambiar message.loading de nuevo a false cuando la respuesta se reciba
                  let ultimaRespuesta = this.messages.pop();

                  let respuestaBot = {
                    from: ultimaRespuesta.from,
                    message: data.respuesta,
                    colorBg: ultimaRespuesta.colorBg,
                    colorText: ultimaRespuesta.colorText,
                    showAvatar: ultimaRespuesta.showAvatar,
                    activable: ultimaRespuesta.activable,
                    loading: false
                  }
                  this.messages.push(respuestaBot)

                  let miMessage = {
                    from: 'You',
                    message: '<p>Volver al menú de preguntas</p>',
                    colorBg: '#BDC0DF',
                    colorText: 'black--text',
                    showAvatar: false,
                    activable: true,   
                    loading: false
                  }
                  this.messages.push(miMessage);

                  this.$nextTick(() => {
                    contenedor.scrollTop = contenedor.scrollHeight;
                  });
                }, 2000);
              }
            }catch (e) {

            }finally {

            }
          }      
        },
      },
      computed: {
        dinamicClass() {
          switch (this.$vuetify.breakpoint.name) {
            case "xs":
              return "";
            case "sm":
              return "text-h6";
            case "md":
              return "text-h6";
            case "lg":
              return "text-h6";
            case "xl":
              return "text-h6";
            case "2xl":
              return "text-h6";
          }
        },
      },
      created(){
        this.loading = true;
        setTimeout(async () => {
          await this.getListPreguntas()
          this.loading = false;
        }, 2000);
      }
    }
</script>

<style scoped>
::v-deep .my-custom-dialog {
    /* align-self: flex-end; */
    position: absolute;
    right: 0px;
    bottom: 9%;
  }
  ::v-deep .my-custom-xs {
    position: absolute;
    right: 0px;
    bottom: 5%;
  }

  /*CHAT*/
  .chat-message {
    display: unset !important;
    white-space: break-spaces;
  }
  .chat-screen {
    max-height: 320px;
    overflow-y: auto;
  }
  .flex-none {
    flex: unset;
  }
  .received-message::after {
    position: absolute;
    width: 0;
    height: 0;
    left: 54px;  
    right: auto;
    top: 12px;
    bottom: auto;
  }

  .sent-message::after {
      position: absolute;
      width: 0;
      height: 0;
      left: auto;    
      right: 54px;
      top: 12px;
      bottom: auto;
  }
  .no-clickable {
    cursor: default  !important;
  }

.contenedor1 {
  max-width:400px; 
  margin: 0; 
  padding: 0;
}
.contenedor2 {
  margin: 0; 
  padding: 0;
}
::v-deep #message1 > p{
  margin-bottom: 0px;
}
</style>