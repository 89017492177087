<template>
  <v-container>
    <v-toolbar-title class=" mt-5" style="color: #313945">
      <div :class="sizeTitleText" class="d-flex justify-center justify-md-start" style="width: 100%">
        <b class="text-h4 text-sm-h3 font-weight-bold ">Mis favoritos</b>
      </div>
    </v-toolbar-title>

    <v-row>
      <v-col cols="12" sm="12" md="7" v-if="items_favoritos.length != 0">
        <v-card :color="bgCards[Math.floor(Math.random() * bgCards.length)]" class="borde mt-10"
                v-for="item in items_favoritos" :key="item.id">
          <v-row no-gutters>
            <v-col cols="12" sm="8" class="pa-0 mt-0" :order="$vuetify.breakpoint.xsOnly ? 2 : null">
              <v-card-title class="ml-5">
                {{ item.nombre }}
              </v-card-title>
            </v-col>
            <v-col cols="12" sm="4" :order="$vuetify.breakpoint.xsOnly ? 1 : null">
              <v-card-actions class="mr-5 d-flex justify-end">
                <v-btn icon v-model="favorito" small @click="marcarFavorito(item)"
                       style="background-color: white;color: rgb(0 0 0 / 0%);">
                  <v-icon color="#22234a" small>{{ item.activo ? 'mdi-heart' : 'mdi-heart-outline' }}</v-icon>
                </v-btn>
              </v-card-actions>
            </v-col>
            <v-col cols="12" class="pa-0" :order="3">
              <v-card-title class="text-h6 text-sm-h5 ml-5 font-weight-bold text-color pt-0" style="padding-bottom: 0;">
                {{ item.titulo }}
              </v-card-title>
            </v-col>
            <v-col cols="12" :order="4">
              <v-card-actions class="d-flex justify-center justify-sm-end mr-5 mb-3">
                <v-btn  rounded color="#22234a" dark class="text-h7 mt-5 text-none"
                       @click="detallesEmpleo(item.id_oferta)">
                  Detalle del empleo
                </v-btn>
<!--                <v-btn v-else rounded color="blue" dark class="text-h7 mt-5 text-none">-->
<!--                  Postulación enviada-->
<!--                </v-btn>-->
<!--                <v-chip color="blue" class="py-5 white&#45;&#45;text text-subtitle-1" rounded v-else>Postulación enviada</v-chip>-->
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col v-else>
        <v-row>
          <v-col class="d-flex flex-column justify-center align-center">
            <v-img style="width: 500px;" src="../../assets/img/NoInvitacionesRRHH.png">
            </v-img>
            <h1 class="mt-5 text-primary-color text-center text-h5 font-weight-black">Aún no tienes ofertas de empleos
              guardadas en Mis favoritos</h1>
          </v-col>

        </v-row>
      </v-col>
      <ChatComponent />
      <!-- Descomentar si hay que colocar nuevamente la imagen -->
      <v-col cols="12" sm="12" md="5" v-if="items_favoritos.length != 0">
        <v-card flat
                class="mx-auto text-center rounded-xxl pa-0 pa-sm-12"
        >
          <v-img src="../../assets/img/imglaboral.svg" class="mx-auto" max-width="570"></v-img>

          <v-card-title class="text-h7 text-sm-h5  font-weight-bold text-color text-justify d-block"
                        style="word-break: break-word">
            Guarda con un
            <v-icon color="#22234a">mdi-heart-outline</v-icon>
            las ofertas de trabajo que te interesen y aplica cuando lo desees
          </v-card-title>

          <v-card-actions class="d-flex justify-center">
            <v-btn outlined rounded class="my-button text-h6 font-weight-bold text-color text-none mb-3 mb-sm-0">
              Ver más empleos
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <app-loader v-if="loading"></app-loader>
  </v-container>
</template>

<script>

import AppLoader from "@/components/AppLoader.vue";
import ChatComponent from "../../components/ChatComponent.vue";
import {mapState} from "vuex";

export default {
  name: "ListaUsuarios",
  components: {AppLoader,ChatComponent},
  data: () => ({
    favorito: true,

    items: [
      {id: 1, nombre: "Diseñador UI/UX", lugar: "Secretaria de innovacion", favorito: false, color: "#e3d3c8"},
      {id: 2, nombre: "Diseñador", lugar: "Precidencia", favorito: false, color: "#d1e1ff"},
    ],
    items_favoritos: [],
    bgCards: [
      '#F6F6F6',
      '#D2D2DB',
      '#E3D3C8',
    ],
    loading: false,
  }),

  watch: {},

  computed: {
    ...mapState(['userInfo'])
  },

  methods: {
    async obtenerFavoritos() {
      if (this.isTokenValido(this.token)) {
        // if(this.tieneRoles(this.token)) {
        try {
          if(typeof this.userInfo.id_persona !== 'undefined'){
            const result = await this.services.favoritos.getFavoritos(this.userInfo.id_persona, null)
            //console.log("lista de favoritos: ", result);
            this.items_favoritos = result.data;
          }
        } catch (error) {
          //console.log(error);
        } finally {

        }

        // } else {
        //   this.temporalAlert({
        //     show:true,
        //     type:"error",
        //     message:"No tiene permiso para listar sus empleos favoritos"
        //   })
        // }

      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "El token ya expiró, debe de volver a iniciar sesión"
        })
        this.$router.replace({name: "login"})
      }
    },

    async marcarFavorito(item) {
      this.loading = true;
      //console.log(item);
      if (this.isTokenValido(this.token)) {
        // if (this.tieneRoles(this.token)) {
          try {
            let eliminar_favorito = await this.services.favoritos.deleteFavorito(item.id)

            // console.log(eliminar_favorito);

            if (eliminar_favorito.status == 200) {
              await this.obtenerFavoritos()
            }
          } catch (error) {
            //console.log(error);
          } finally {
            this.loading = false;
          }

          item.favorito = this.favorito
          this.favorito = !this.favorito;

        // } else {
        //   this.temporalAlert({
        //     show: true,
        //     type: "error",
        //     message: "No tiene permiso para marcar favorito"
        //   })
        // }

      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: "El token ya expiró, debe de volver a iniciar sesión"
        })
        this.$router.replace({name: "login"})
      }

    },

    detallesEmpleo(id) {
      if (id != null) {
        this.$router.push({path: `/empleo/${id}`, params: {id_oferta: id}});
      }
    }
  },


  computed: {
    sizeTitleText() {

      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 'text-h4'
          break;
        case "md":
          return 'text-h4'
          break;
        case "sm":
          return 'text-h5'
          break;
        case "xs":
          return 'text-h6'
          break;
        default:
          return 'text-h4'
          break;
      }
    },
    ...mapState(["userInfo", "token"]),
  },

  async created() {
    this.loading = true;
    setTimeout(async () => {
      await this.obtenerFavoritos();
      this.loading = false;
    }, 2000);

  }

}
</script>

<style scoped>
.text-color {
  color: #313945;
}

.borde {
  border-radius: 20px;
}

.alineacionIcono {
  margin-left: 820px;
  margin-top: 5px;
}

.Posicion-card {
  margin-left: 150px;
  margin-top: -90px
}

.posicion-text {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 138px;
}

img {
  /* max-width: 100%; */
  width: 750px;
}

.my-button:hover {
  background-color: #333458;
  color: white;
}

@media only screen and(max-width: 600px) {
  .imgResponsive {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}
</style>
